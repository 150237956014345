import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./Assets/Css/Web.css";
import Login from "./Pages/Login";
import Dashboard from "./Pages/Dashboard";
import Payment from "./Pages/Payment";
import Request from "./Pages/Requests";
import Driver from "./Pages/Driver";
import User from "./Pages/User";
import AuthProvider from "./AuthProvider";
import PrivateRoute from "./route";
import Request_city from "./Pages/Request_city";
import Setting from "./Pages/Setting";
import Hours from "./Pages/Hours";
import Promo from "./Pages/Promo";
import Details from "./Pages/Details";
import Adv from "./Pages/adv";
import Transactions from "./Pages/Transactions";
import DetailsRequests from "./Pages/DetailsRequests";
import DetailsRequestsCity from "./Pages/DetailsRequestsCity";

function App() {
  

  return (
    <div className="App">
      <Router>
        <AuthProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route element={<PrivateRoute />}>
              <Route path="/Dashboard" element={<Dashboard />} />
              <Route path="/Transactions" element={<Transactions />} />
              <Route path="/Payment" element={<Payment />} />
              <Route exact path="/Request" element={<Request />} />
              <Route exact path="/Request_city" element={<Request_city />} />
              <Route exact path="/Driver" element={<Driver />} />
              <Route exact path="/User" element={<User />} />
              <Route exact path="/Setting" element={<Setting />} />
              <Route exact path="/Hours" element={<Hours />} />
              <Route exact path="/Promo" element={<Promo />} />
              <Route exact path="/Adv" element={<Adv />} />
              <Route exact path="/Details/:uuid" element={<Details />} />
              <Route exact path="/DetailsRequests/:uuid" element={<DetailsRequests />} />
              <Route exact path="/DetailsRequestsCity/:uuid" element={<DetailsRequestsCity />} />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </div>
  );
}


export default App;
