import React, { useState, useEffect } from "react";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";

function Driver() {
  const [drivers, setDrivers] = useState([]);
  const auth = useAuth();

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://app.dogdriveapp.com/api/v2/users/drivers",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      // console.log("response", response.data.data.result);
      setDrivers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <Table />
          </div>
        </div>
      </div>
    </>
  );
}

export default Driver;
