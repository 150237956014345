import React, { useState, useEffect } from "react";
import { useParams, useLocation } from "react-router";
import Sidebar from "../Sidebar/Sidebar";
import Navbar from "../Navbar/Navbar";
import Table from "../Table/Table";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";
import { useNavigate } from "react-router-dom";

function DetailsRequests(params) {
  const location = useLocation();
  const [post, setPost] = useState({});
  useEffect(() => {
    setPost(location.state.item);
  }, []);
  const auth = useAuth();
  const navigate = useNavigate();



  return (
    <>
      <div className="main">
        <div className="sidebar">
          <Sidebar />
        </div>
        <div className="dev-table">
          <Navbar />
          <div className="table-card">
            <div className="details">
            dd
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DetailsRequests;
