import React, { useState, useEffect } from "react";
import "../Assets/Css/Table.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { IconField } from "primereact/iconfield";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { Form } from "react-bootstrap";
import axios from "axios";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import { useAuth } from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import { axiosConfig } from "../axiosAPI"

function Table() {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [area, setArea] = useState([]);
  // const [formData, setFormData] = useState({});
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  const [loading, setLoading] = useState(false);
  const auth = useAuth();
  const [name, setName] = useState("");
  const [Phone, setPhone] = useState("");
  const [ID, setID] = useState("");
  const [model, setModal] = useState("");
  const [Dog, setDog] = useState("");
  const [license, setLicense] = useState("");
  const [selectedCell, setSelectedCell] = useState(null);
  const [metaKey, setMetaKey] = useState(true);
  const [selectedItem, setSelectedItem] = useState(null);

  const getData = async () => {
    try {
      const response = await axiosConfig.get(
        "https://app.dogdriveapp.com/api/v2/users/drivers",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setCustomers(response.data.data.result);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const getArea = async () => {
    try {
      const response = await axiosConfig.get(
        "https://app.dogdriveapp.com/api/v2/areas",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setArea(response.data.data);
      // console.log(response.data.data);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };

  const onGlobalFilterChange = (e) => {
    const value = e.target.value;
    let _filters = { ...filters };

    _filters["global"].value = value;

    setFilters(_filters);
    setGlobalFilterValue(value);
  };
  const renderHeader = () => {
    return (
      <div className="flex justify-content-end">
        <IconField iconPosition="left">
          <i className="fa fa-search"></i>
          <InputText
            value={globalFilterValue}
            onChange={onGlobalFilterChange}
            placeholder="Search"
          />
        </IconField>
      </div>
    );
  };
  const header = renderHeader();
  const onRowEditComplete = (e) => {
    let _customers = [...customers];
    let { newData, index } = e;

    _customers[index] = newData;

    setCustomers(_customers);
  };
  useEffect(() => {
    getArea();
    getData().then((data) => {
      setLoading(false);
    });
  }, []);
  const [visible, setVisible] = useState(false);
  const headerElement = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Edit Driver</span>
    </div>
  );
  const footerContent = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          editUser();
          setVisible(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );
  const [visible2, setVisible2] = useState(false);
  const headerElement2 = (
    <div className="inline-flex align-items-center justify-content-center gap-2">
      <span className="font-bold white-space-nowrap">Add Driver</span>
    </div>
  );
  const footerContent2 = (
    <div className="modal-button">
      <Button
        label="Cancel"
        className="cancel"
        onClick={() => setVisible2(false)}
        autoFocus
      />
      <Button
        label="Save"
        type="submit"
        onClick={() => {
          handleSubmit();
          setVisible2(false);
        }}
        className="save"
        autoFocus
      />
    </div>
  );

  const [formData, setFormData] = useState({
    FullName: "",
    PhoneNumber: "",
    Password: "",
    NationalID: "",
    CarModel: "",
    CarLicense: "",
    HaveADog: [],
    CoverageAreaUuid: [],
    FrontNationalIDFile: [],
    BackNationalIDFile: [],
    FrontDriverLicenseFile: [],
    BackDriverLicenseFile: [],
    FrontCarLicenseFile: [],
    BackCarLicenseFile: [],
    CriminalRecordFile: [],
    DrugTestFile: [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    // e.preventDefault();
    try {
      const response = await axiosConfig.post(
        "https://app.dogdriveapp.com/api/v2/users/drivers/create",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      getData();
      console.log(response.data);
    } catch (error) {
      console.error(error);
    }
    setFormData("");
  };

  // console.log(selectedItem);
  const gotToNewPage = (rowData) => {
    return <a
      className="edit"
      onClick={(e) => {
        navigate(`/Details/${rowData.uuid}`, { state: { item: rowData } });
      }}
    >
      details
    </a>;
  };

  const editUser = async () => {
    const response = await axiosConfig
      .put(
        "https://app.dogdriveapp.com/api/v2/users/drivers/" +
          formData?.uuid +
          "/update",
        formData,
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "multipart/form-data",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const blockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://app.dogdriveapp.com/api/v2/users/${uuid}/block`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const unBlockUser = async (uuid) => {
    const response = await axiosConfig
      .put(`https://app.dogdriveapp.com/api/v2/users/${uuid}/unblock`, {
        headers: {
          Authorization: "Bearer " + auth.token,
          "Content-Type": "application/json",
          "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
        },
      })
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
  };

  const approveUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://app.dogdriveapp.com/api/v2/users/drivers/" + uuid + "/approve",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid)
  }

  const rejectUser = async (uuid) => {
    const response = await axiosConfig
      .put(
        "https://app.dogdriveapp.com/api/v2/users/drivers/" + uuid + "/reject",
        {},
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "Content-Type": "application/json",
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      )
      .then((res) => {
        getData();
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
        });
      });
    // alert(uuid);
  };

  const statusBodyTemplate = (rowData) => {
    
      return rowData.applicationStatus == "Pending" ? (
        <>
          <div className="action-table">
            <a
              className="approve"
              onClick={(e) => {
                approveUser(rowData.uuid);
              }}
            >
              <i className="fa fa-check"></i>
            </a>
            <a
              className="delete"
              onClick={(e) => {
                rejectUser(rowData.uuid);
              }}
            >
              <i className="fa fa-close"></i>
            </a>
          </div>
        </>
      ) : rowData.applicationStatus == "Approved" ? (
        <>
          <p>Approved</p>
        </>
      ) : (
        <>
          <p>Rejected</p>
        </>
      );
  };

  const statusAction = (rowData) => {
      return (
        <>
          <div className="action-table">
            <a
              className="edit"
              onClick={(e) => {
                setVisible(true);
                setFormData(rowData);
              }}
            >
              <i className="fa fa-pen"></i>
            </a>
            {rowData.stateLocalized == "Enabled" ? (
              <a
                className="delete"
                onClick={(e) => {
                  blockUser(rowData.uuid);
                }}
              >
                <i className="fa fa-ban"></i>
              </a>
            ) : (
              <a
                className="delete"
                onClick={(e) => {
                  unBlockUser(rowData.uuid);
                }}
              >
                <i className="fa fa-unlock"></i>
              </a>
            )}
          </div>
        </>
      );
  };


  return (
    <>
      <div className="table">
        <a className="add">
          <i
            className="fa fa-plus"
            onClick={(e) => {
              setVisible2(true);
            }}
          ></i>
        </a>
        <DataTable
          value={customers}
          editMode="row"
          paginator
          showGridlines
          rows={10}
          rowsPerPageOptions={[5, 10, 25, 50]}
          tableStyle={{ minWidth: "700px" }}
          loading={loading}
          //   dataKey="id"
          filters={filters}
          globalFilterFields={[
            "fullName",
            "carModel",
            "carLicense",
            "uuid",
            "nationalID",
            "applicationStatusLocalized",
          ]}
          header={header}
          emptyMessage="No customers found."
          onRowEditComplete={onRowEditComplete}
          dataKey="uuid"
          cellSelection
          selectionMode="single"
          onSelectionChange={(e) => {
            setSelectedCell(e.value);
            setSelectedItem(e.value.value);
          }}
        >
          <Column
            field="fullName"
            header="Driver Name"
            style={{ width: "14.28%" }}
          ></Column>
          <Column
            field="carModel"
            header="Car model"
            style={{ width: "14.28%" }}
          ></Column>
          <Column
            field="carLicense"
            header="Car License "
            style={{ width: "14.28%" }}
          ></Column>
          <Column
            field="nationalID"
            header="national ID"
            style={{ width: "14.28%" }}
          ></Column>
          <Column
            field="applicationStatusLocalized"
            header="Application Status"
            style={{ width: "14.28%" }}
            body={statusBodyTemplate}
          ></Column>
          <Column
            field="uuid"
            body={gotToNewPage}
            header="Details"
            style={{ width: "14.28%" }}
          ></Column>
          <Column
            field="uuid"
            body={statusAction}
            header=""
            style={{ width: "14.28%" }}
          ></Column>
        </DataTable>
        <Dialog
          visible={visible2}
          modal
          header={headerElement2}
          footer={footerContent2}
          style={{ width: "50rem" }}
          onHide={() => {
            if (!visible2) return;
            setVisible2(false);
          }}
        >
          <Form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="FullName"
                    placeholder="Saqer"
                    value={formData.FullName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="PhoneNumber"
                    placeholder="01143987449"
                    value={formData.PhoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    name="Password"
                    placeholder="******* **"
                    value={formData.Password}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>National ID</Form.Label>
                  <Form.Control
                    type="number"
                    name="NationalID"
                    placeholder="**** **** *888"
                    value={formData.NationalID}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Car model</Form.Label>
                  <Form.Control
                    type="text"
                    name="CarModel"
                    placeholder="2014"
                    value={formData.CarModel}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Car License</Form.Label>
                  <Form.Control
                    type="text"
                    name="CarLicense"
                    placeholder="car license"
                    value={formData.CarLicense}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Cover age Area</Form.Label>
                  <select
                    onChange={handleChange}
                    name="CoverageAreaUuid"
                    className="form-select"
                  >
                    {area.map((x, index) => (
                      <option value={x.uuid} key={index}>
                        {x.displayName}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Have A Dog</Form.Label>
                  <select
                    onChange={handleChange}
                    name="HaveADog"
                    className="form-select"
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front National ID File</Form.Label>
                  <Form.Control
                    id="FrontNationalIDFile"
                    className="file"
                    type="file"
                    name="FrontNationalIDFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back National ID File</Form.Label>
                  <Form.Control
                    id="BackNationalIDFile"
                    className="file"
                    type="file"
                    name="BackNationalIDFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front Driver License File</Form.Label>
                  <Form.Control
                    id="FrontDriverLicenseFile"
                    className="file"
                    type="file"
                    name="FrontDriverLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back Driver License File</Form.Label>
                  <Form.Control
                    id="BackDriverLicenseFile"
                    className="file"
                    type="file"
                    name="BackDriverLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front Car License File</Form.Label>
                  <Form.Control
                    id="FrontCarLicenseFile"
                    className="file"
                    type="file"
                    name="FrontCarLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back Car License File</Form.Label>
                  <Form.Control
                    id="BackCarLicenseFile"
                    className="file"
                    type="file"
                    name="BackCarLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Criminal Record File</Form.Label>
                  <Form.Control
                    id="CriminalRecordFile"
                    className="file"
                    type="file"
                    name="CriminalRecordFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Drug Test File</Form.Label>
                  <Form.Control
                    id="DrugTestFile"
                    className="file"
                    type="file"
                    name="DrugTestFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Dialog>
        <Dialog
          visible={visible}
          modal
          header={headerElement}
          footer={footerContent}
          style={{ width: "50rem" }}
          onHide={() => {
            if (!visible) return;
            setVisible(false);
          }}
        >
          <Form>
            <div className="row">
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="fullName"
                    placeholder="Saqer"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="tel"
                    name="phoneNumber"
                    placeholder="01143987449"
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>National ID</Form.Label>
                  <Form.Control
                    type="number"
                    name="nationalID"
                    placeholder="**** **** *888"
                    value={formData.nationalID}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Car model</Form.Label>
                  <Form.Control
                    type="text"
                    name="carModel"
                    placeholder="2014"
                    value={formData.carModel}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Car License</Form.Label>
                  <Form.Control
                    type="text"
                    name="carLicense"
                    placeholder="car license"
                    value={formData.carLicense}
                    onChange={handleChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Cover age Area</Form.Label>
                  <select
                    onChange={handleChange}
                    name="CoverageAreaUuid"
                    className="form-select"
                  >
                    {area.map((x, index) => (
                      <option value={x.uuid} key={index}>
                        {x.displayName}
                      </option>
                    ))}
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Have A Dog</Form.Label>
                  <select
                    onChange={handleChange}
                    name="HaveADog"
                    className="form-select"
                  >
                    <option value={true}>true</option>
                    <option value={false}>false</option>
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front National ID File</Form.Label>
                  <Form.Control
                    id="FrontNationalIDFile"
                    className="file"
                    type="file"
                    name="FrontNationalIDFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back National ID File</Form.Label>
                  <Form.Control
                    id="BackNationalIDFile"
                    className="file"
                    type="file"
                    name="BackNationalIDFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front Driver License File</Form.Label>
                  <Form.Control
                    id="FrontDriverLicenseFile"
                    className="file"
                    type="file"
                    name="FrontDriverLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back Driver License File</Form.Label>
                  <Form.Control
                    id="BackDriverLicenseFile"
                    className="file"
                    type="file"
                    name="BackDriverLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Front Car License File</Form.Label>
                  <Form.Control
                    id="FrontCarLicenseFile"
                    className="file"
                    type="file"
                    name="FrontCarLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Back Car License File</Form.Label>
                  <Form.Control
                    id="BackCarLicenseFile"
                    className="file"
                    type="file"
                    name="BackCarLicenseFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Criminal Record File</Form.Label>
                  <Form.Control
                    id="CriminalRecordFile"
                    className="file"
                    type="file"
                    name="CriminalRecordFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
              <div className="col-md-6">
                <Form.Group className="form-group">
                  <Form.Label>Drug Test File</Form.Label>
                  <Form.Control
                    id="DrugTestFile"
                    className="file"
                    type="file"
                    name="DrugTestFile"
                    placeholder="text"
                    onChange={handleFileChange}
                  />
                </Form.Group>
              </div>
            </div>
          </Form>
        </Dialog>
      </div>
    </>
  );
}

export default Table;
