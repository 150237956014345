import React, { useState, useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import Swal from "sweetalert2";
import { useAuth } from "../AuthProvider";
import { axiosConfig } from "../axiosAPI";

ChartJS.register(ArcElement, Tooltip, Legend);

function Option() {
  const [users, setUsers] = useState([]);
  const auth = useAuth();
  const getUsers = async () => {
    try {
      const response = await axiosConfig.get(
        "https://app.dogdriveapp.com/api/v2/dashboard/users-no",
        {
          headers: {
            Authorization: "Bearer " + auth.token,
            "x-api-key": "fab6c94fc0044b53bf5e886a8fef1325",
          },
        }
      );
      setUsers(response.data);
      console.log(response.data);
      // console.log(response.data.message);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
      });
    }
  };
  useEffect(() => {
    getUsers();
  }, []);

  let data = [
    {
      label: users?.message,
      value: users?.data,
      cutout: "80%",
    },
  ];

  const options = {
    plugins: {
      responsive: true,
    },
    cutout: data.map((item) => item.cutout),
  };

  const finalData = {
    labels: data.map((item) => item.label),
    datasets: [
      {
        data: data.map((item) => Math.round(item.value)),
        backgroundColor: "rgba(226, 57, 4, 1)",
      },
    ],
  };

  return <Doughnut data={finalData} options={options} />;
}

export default Option;
